import React, { useState } from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';

import './Home.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import img1 from '../../assets/jpg/img1.jpg';
import img2 from '../../assets/jpg/img2.jpg';
import img3 from '../../assets/jpg/img3.jpg';
import img4 from '../../assets/jpg/img4.jpg';
import img5 from '../../assets/jpg/img5.jpeg';
import img6 from '../../assets/jpg/Celda de reina1.JPG';
import img7 from '../../assets/jpg/Celda de reina2.JPG';
import img8 from '../../assets/jpg/Celda de reina3.JPG';
import img9 from '../../assets/jpg/Cosecha1.JPG';
import img10 from '../../assets/jpg/Cosecha2.JPG';
import img11 from '../../assets/jpg/Extractor.JPG';
import img12 from '../../assets/jpg/Monte de carden.JPG';
import img13 from '../../assets/jpg/Operculadora.JPG';
import img14 from '../../assets/jpg/tamai4.JPG';
import img15 from '../../assets/jpg/Tamai5.JPG';
import logo from '../../assets/png/logo.png';
import logoHeader from '../../assets/png/logo-unitro-header.png';
import argentinaIcon from '../../assets/png/argentinaIcon.png';
import flower from '../../assets/svg/flower.svg';
import hives from '../../assets/svg/hives.svg';

const images = [
	// {
	// 	src: img1,
	// },
	// {
	// 	src: img2,
	// },
	// {
	// 	src: img3,
	// },
	// {
	// 	src: img4,
	// },
	// {
	// 	src: img5,
	// },
	{
		src: img6,
	},
	{
		src: img7,
	},
	{
		src: img8,
	},
	{
		src: img9,
	},
	{
		src: img10,
	},
	{
		src: img11,
	},
	{
		src: img12,
	},
	{
		src: img13,
	},
	{
		src: img14,
	},
	{
		src: img15,
	},
];
const videos = [
	{
		image: 'https://img.youtube.com/vi/C4d9SoAfFsQ/0.jpg',
		video: 'https://www.youtube.com/embed/C4d9SoAfFsQ',
	},
	{
		image: 'https://img.youtube.com/vi/XbH_EVMljOI/0.jpg',
		video: 'https://www.youtube.com/embed/XbH_EVMljOI',
	},
	{
		image: 'https://img.youtube.com/vi/p3pjVKQ2QFw/2.jpg',
		video: 'https://www.youtube.com/embed/p3pjVKQ2QFw',
	},
	{
		image: 'https://img.youtube.com/vi/DTiKddbRIec/2.jpg',
		video: 'https://www.youtube.com/embed/DTiKddbRIec',
	},
];

export const Home = () => {
	const [video, setVideo] = useState('');
	const [photo, setPhoto] = useState('');
	const [modalPhoto, setModalPhoto] = useState(false);
	const [modalVideo, setModalVideo] = useState(false);
	let { lote } = useParams();

	const togglePhoto = (photo) => {
		setPhoto(photo);
		setModalPhoto(!modalPhoto);
	};
	const toggleVideo = (video) => {
		setVideo(video);
		setModalVideo(!modalVideo);
	};

	return (
		<div>
			<Row className="header-logo-unitro">
				<img src={logoHeader} alt="" />
			</Row>
			<div style={{ backgroundColor: '#fff' }}>
				<div className="lot-details-box">
					<Row className="lot-details">
						<Col xs={12} sm={12} md={12} className="lot-details-header">
							<span>
								{/* LOT: */}
								{lote}
							</span>
						</Col>
						<Col xs={11} sm={11} md={11} className="beekeeper-details">
							<div className="beekeeper-details-title">
								<span>BEEKEEPER</span>
							</div>
							<div className="beekeeper-details-name">
								<span>Hikaru Tamai</span>
							</div>
							<div className="separator"></div>
						</Col>
						<Col xs={11} sm={11} md={11} className="beekeeper-details">
							<div className="beekeeper-details-title">
								<span>ORIGIN</span>
							</div>
							<div className="beekeeper-details-name origin-box">
								<span>Argentina</span>
								<img src={argentinaIcon} alt="" />
							</div>
							<div className="separator"></div>
						</Col>
						<Col xs={11} sm={11} md={11} className="beekeeper-details">
							<Row>
								<Col xs={7} sm={7} md={7}>
									<div className="beekeeper-details-title">
										<img src={flower} alt="" />
										<span>{' FLOWER SOURCE'}</span>
									</div>
									<div className="beekeeper-details-name origin-box">
										<span>Polyfloral</span>
									</div>
								</Col>
								<Col xs={4} sm={4} md={4}>
									<div className="beekeeper-details-title">
										<img src={hives} alt="" />
										<span>{' HIVES'}</span>
									</div>
									<div className="beekeeper-details-name origin-box">
										<span>1300</span>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<div style={{ width: '85%', margin: 'auto' }}>
					<Row className="photos-label">
						<span>PHOTOS</span>
					</Row>

					<Row className="photos">
						<div className="caroussel-bannerX">
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									backgroundColor: '#fff',
									borderRadius: '10px',
								}}
							>
								{images.map((item, index) => {
									return (
										<div
											key={index}
											className="caroussel-photo"
											onClick={() => {
												togglePhoto(item.src);
											}}
										>
											<img src={item.src} alt="" />
										</div>
									);
								})}
							</div>
						</div>
					</Row>
					{/* 
					<Row className="photos-label">
						<span style={{ fontWeight: '500' }}>VIDEOS</span>
					</Row>
					<Row className="photos">
						<div className="caroussel-bannerX">
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									backgroundColor: '#fff',
									borderRadius: '10px',
								}}
							>
								{videos.map((item, index) => {
									return (
										<div
											key={index}
											className="caroussel-photo"
											onClick={() => {
												toggleVideo(item.video);
											}}
										>
											<img src={item.image} alt="" />
										</div>
									);
								})}
							</div>
						</div>
					</Row> */}
				</div>
				<Row>
					<Col>
						<Link to={`/importer-documents/${lote}`} style={{ textDecoration: 'none' }}>
							<div className="importer-view-box">
								<span className="importer-view-button">IMPORTER DOCUMENTS</span>
							</div>
						</Link>
					</Col>
				</Row>
				<Row className="contact-us">
					<a target="_blank" rel="noreferrer" href="https://hunitro.com/contact/">
						CONTACT US
					</a>
				</Row>
				<Row>
					<div style={{ margin: '1rem 0' }}>
						<img style={{ height: '50px' }} src={logo} alt="" />
					</div>
				</Row>
			</div>
			<div>
				<Modal isOpen={modalVideo} toggle={toggleVideo} id="modal" size="lg" style={{ width: '90%', position: 'absolute', top: '30%', left: '3%' }}>
					<ModalBody className="modal-body">
						<iframe
							width="100%"
							height="315"
							src={video}
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</ModalBody>
				</Modal>
			</div>
			<div>
				<Modal isOpen={modalPhoto} toggle={togglePhoto} id="modal" size="lg" style={{ width: '90%', position: 'absolute', top: '30%', left: '3%' }}>
					<ModalBody className="modal-body">
						<img style={{ width: '100%' }} src={photo} alt="" />
					</ModalBody>
				</Modal>
			</div>
		</div>
	);
};
