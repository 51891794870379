import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Home } from '../components/Home/Home';
import { ImporterDocument } from '../components/ImporterDocument/ImporterDocument';
import { ImporterDocuments } from '../components/ImporterDocuments/ImporterDocuments';
export const AppRouter = () => {
	return (
		<>
			<Router>
				<Switch>
					<Route exact path="/:lote" component={Home} />
					<Route exact path="/importer-documents/:lote" component={ImporterDocuments}></Route>
					<Route exact path="/importer/:doc/:lote" component={ImporterDocument}></Route>
				</Switch>
			</Router>
		</>
	);
};
